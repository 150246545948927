import React, { useRef, useState, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import FormRenderer from 'components/common/FormRenderer'
import SavedSearchModal from './SavedSearchModal'

import { SavedSearchModalProvider } from 'contexts/SavedSearchModalContext'

import isEmptyObject from 'helpers/node/isEmptyObject'

import useSavedSearch from 'hooks/search/useSavedSearch'
import useConfiguration from 'hooks/useConfiguration'
import useUsers from 'hooks/users/useUsers'
import useAuth from 'hooks/useAuth'

import encodeBase64 from 'helpers/node/encodeBase64'
import trimString from 'helpers/node/trimString'
import { MODULE_NAMES } from 'utils/constants'

function CorrespondenceSearch({
  initialData,
  baseActiveId = -1,
  scrollHeight,
  isPage,
  hideHeaderActions = false,
  extraFooterActionProps = [],
}) {
  const [, setSearchParams] = useSearchParams()
  const { user } = useAuth()
  const { updateUser } = useUsers()
  const navigate = useNavigate()
  const { getModuleFromName } = useConfiguration()
  const formikRef = useRef(null)
  const currentModuleId = getModuleFromName(MODULE_NAMES.CORRESPONDENCE)?.id

  // saved search states
  const [activeSfc, setActiveSfc] = useState({})
  const [activeId, setActiveId] = useState(baseActiveId)
  const [renderingData, setRenderingData] = useState([])

  // form renderer states
  const [headerActionProps, setHeaderActionProps] = useState([
    {
      children: 'Add +',
      color: 'primary',
      variant: 'outlined',
      bgcolor: 'white',
      onClick: () => {
        setModalType('add')
      },
    },
  ])

  const subActionProps = {
    children: 'Edit search set',
    onClick: () => {
      setModalType('edit')
    },
  }

  const footerActionProps = [
    {
      children: 'Search',
      color: 'primary',
      variant: 'contained',
      type: 'submit',
    },
    !hideHeaderActions && {
      children: 'Clear',
      color: 'primary',
      variant: 'outlined',
      bgcolor: 'white',
      onClick: () => {
        formikRef.current.resetForm({ values: {} })

        if (!isPage) formikRef.current.submitForm()
      },
    },
    ...extraFooterActionProps,
  ].filter((actionProps) => actionProps)

  // modal states
  const [modalType, setModalType] = useState('closed')

  const { savedSearches, loading, mutate } = useSavedSearch({
    currentModuleId,
  })

  const savedSearchProps = useMemo(
    () =>
      savedSearches.map((sfc) => ({
        sfc,
        children: sfc.name,
        name: sfc.name,
        active: activeId === sfc.id ? 'true' : undefined,
        onClick: () => {
          setActiveId(sfc.id)
        },
      })),
    [savedSearches, activeId]
  )

  const getDefaultSavedSearch = () => {
    let defaultSavedSearch = {}

    const defaultSavedSearches = savedSearchProps.filter(
      (ssp) => ssp.sfc.is_default
    )

    if (defaultSavedSearches.length <= 0) {
      defaultSavedSearch = savedSearchProps[0]
    } else {
      defaultSavedSearch = defaultSavedSearches.find(
        (dss) => dss.sfc.is_user_defined
      )

      if (!defaultSavedSearch) defaultSavedSearch = defaultSavedSearches[0]
    }

    return defaultSavedSearch
  }

  const defaultSS = useMemo(getDefaultSavedSearch, [savedSearchProps])

  const setActiveSavedSearch = () => {
    const foundActiveSfc = savedSearches.find((sfc) => sfc.id === activeId)

    if (activeId !== -1 && !foundActiveSfc) return

    if (foundActiveSfc) setActiveSfc(foundActiveSfc)
    else setActiveSfc(defaultSS?.sfc || {})
  }

  const validate = async (values) => {
    const errors = {}
    let isDirty = false

    await Promise.all(
      Object.values(values).map(async (value) => {
        if (isDirty) return

        if (Array.isArray(value))
          isDirty = Boolean(value.find((val) => val !== undefined))
        else if (typeof value === 'object' && !isEmptyObject(value))
          isDirty = true
        else if (typeof value !== 'object' && value) isDirty = true

        return null
      })
    )

    if (!isDirty) {
      errors.submit = 'At least one field is required'
    }

    return errors
  }

  const handleSubmit = (values) => {
    const searchValues = {}

    activeSfc.fields.forEach((field) => {
      const searchValue = trimString(values[field.field])
      let path = field.path

      // search on trimmed check number override, for chubb
      if (user.instanceId === 'chubb' && path === '_.metadata.CheckNumber') {
        path = '_.metadata.CheckNumberTrimmed'
      }

      searchValues[path] = searchValue
    })

    // store search values in url for back button persistence, only on page
    if (isPage) {
      const searchString = encodeBase64(
        JSON.stringify({
          ...searchValues,
          activeId,
        })
      )
      setSearchParams({ q: searchString })
    }

    navigate(`/correspondence-hub/correspondence/results`, {
      state: {
        fields: activeSfc.resultColumns.map((rc) => rc.path).filter((rc) => rc),
        displays: activeSfc.resultColumns
          .map((rc) => rc.display)
          .filter((rc) => rc),
        values: searchValues,
        activeId,
      },
    })

    // save users last saved search
    if (
      activeSfc?.id &&
      user?.lastUsedSearchId &&
      activeSfc.id !== user.lastUsedSearchId
    ) {
      updateUser({
        userId: user.userId,
        userData: {
          last_used_search_id: activeSfc.id,
        },
      })
    }
  }

  useEffect(setActiveSavedSearch, [activeId, savedSearches, defaultSS])

  // set search field config buttons in component header
  useEffect(() => {
    if (savedSearchProps.length > 0) {
      // insert all user-defined searches between default and Add +
      setHeaderActionProps([
        {
          ...defaultSS,
          active: activeSfc?.id === defaultSS.sfc?.id ? 'true' : undefined,
        },
        ...savedSearchProps.filter((ssp) => ssp?.name !== defaultSS?.name),
        headerActionProps.slice(-1)[0],
      ])
    }
  }, [savedSearches, activeSfc])

  // change displayed fields whenever the current
  // search field configuration is changed
  useEffect(() => {
    // reset form on saved search change
    if (formikRef.current) formikRef.current.resetForm()

    if (!isEmptyObject(activeSfc)) setRenderingData(activeSfc.fields)
  }, [activeSfc])

  useEffect(() => {
    setActiveId(baseActiveId)
  }, [baseActiveId])

  const savedSearchModal = useMemo(
    () => (
      <SavedSearchModalProvider>
        <SavedSearchModal
          type={modalType}
          setType={setModalType}
          defaultSfc={defaultSS?.sfc}
          activeSfc={activeSfc}
          title={activeSfc?.name}
          fetchSearchConfig={mutate}
          setActiveId={setActiveId}
          moduleId={currentModuleId}
        />
      </SavedSearchModalProvider>
    ),
    [modalType, defaultSS, currentModuleId, mutate]
  )

  return (
    <>
      {!loading && renderingData.length > 0 && (
        <FormRenderer
          renderingData={renderingData}
          headerActionProps={hideHeaderActions ? null : headerActionProps}
          subActionProps={
            activeSfc.is_user_defined ? subActionProps : undefined
          }
          footerActionProps={footerActionProps}
          innerRef={formikRef}
          validate={validate}
          handleSubmit={handleSubmit}
          initialData={initialData}
          scrollHeight={scrollHeight}
          isCorrespondence={true}
        />
      )}
      {savedSearchModal}
    </>
  )
}

export default CorrespondenceSearch
