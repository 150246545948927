import { useState, useEffect } from 'react'
import useSWR from 'swr'

import axios from 'utils/axios'
import fetcher from 'utils/fetcher'

const usePrintAsset = ({ assetId }) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [data, setData] = useState({})

  const {
    data: baseData,
    isValidating,
    mutate,
  } = useSWR(
    assetId
      ? `/print-assets/${assetId}?embed=user|versions|document|rules|note`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const getPrintAsset = async ({ id }) => {
    return axios.get(`/print-assets/${id}`)
  }

  const updatePrintAsset = async ({
    id = assetId,
    updateData,
    isForm = true,
    etag = data?.etag,
  }) => {
    const errorMsg = 'Error updating print asset'

    if (!updateData || !id) throw new Error()

    const formData = new FormData()

    if (isForm) {
      Object.entries(updateData).forEach(([key, value]) => {
        if (key === 'file' && !value) return

        // value can be falsey
        if (value !== undefined || value !== null) {
          // check for redundancies
          if (value !== data[key]) {
            formData.append(key, value)
          }
        }
      })
    }

    const res = await axios.patch(
      `/print-assets/${id}`,
      isForm ? formData : updateData,
      {
        headers: {
          'Content-Type': isForm ? 'multipart/form-data' : undefined,
          'If-Match': etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const addNewPrintAssetVersion = async ({
    id = assetId,
    updateData,
    isForm = true,
  }) => {
    const errorMsg = 'Error updating print asset'

    if (!updateData || !id) throw new Error()

    const formData = new FormData()

    if (baseData.internal) {
      updateData.internal = baseData.internal
      formData.append('internal', 1)
    }

    if (isForm) {
      Object.entries(updateData).forEach(([key, value]) => {
        if (key === 'file' && !value) return

        // value can be falsey
        if (value !== undefined || value !== null) {
          // check for redundancies
          if (value !== data[key]) {
            formData.append(key, value)
          }
        }
      })
    }

    const res = await axios.post(
      `/print-assets/${id}/versions`,
      isForm ? formData : updateData,
      {
        headers: {
          'Content-Type': isForm ? 'multipart/form-data' : undefined,
          'If-Match': data?.etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const updatePrintAssetVersion = async ({
    id = assetId,
    version = 1,
    updateData,
  }) => {
    const errorMsg = 'Error updating print asset'

    if (!updateData || !id || !version) throw new Error()

    const formData = new FormData()

    Object.entries(updateData).forEach(([key, value]) => {
      if (key === 'file' && !value) return

      // value can be falsey
      if (value !== undefined || value !== null) {
        // check for redundancies
        if (value !== data[key]) {
          formData.append(key, value)
        }
      }
    })

    const {
      headers: { etag },
    } = await axios.head(`/print-assets/${id}/versions/${version}`)
    const res = await axios.patch(
      `/print-assets/${id}/versions/${version}`,
      updateData,
      {
        headers: {
          'If-Match': etag,
        },
      }
    )

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  const deletePrintAsset = async ({ id = assetId }) => {
    const errorMsg = 'Error deleting print asset'

    if (!id) throw new Error(errorMsg)

    const res = await axios.delete(`/print-assets/${id}`, {
      headers: {
        'If-Match': data?.etag,
      },
    })

    if (res.status !== 204) {
      throw new Error(errorMsg)
    }
  }

  const addPrintAssetNote = async ({ id = assetId, note }) => {
    const errorMsg = 'Error adding note to print asset'

    if (!note || !id) throw new Error()

    const res = await axios.post(`/print-assets/${id}/notes`, { note })

    if (res.status !== 200) {
      throw new Error(errorMsg)
    } else {
      await mutate()
    }
  }

  useEffect(() => {
    if (!assetId) {
      return
    }
    setDataLoading(true)
    axios
      .get(`/print-assets/${assetId}`)
      .then((res) => {
        const etag = res.headers.etag

        const versions = baseData?._embedded?.versions?.versions || []
        const currentVersion = versions.find(
          (version) =>
            version.version?.toString() ===
            baseData?._embedded?.versions?.default?.toString()
        )

        if (typeof currentVersion?.custom_data === 'string') {
          try {
            currentVersion.custom_data = JSON.parse(currentVersion.custom_data)
          } catch (err) {
            // pass
          }
        }

        const newData = {
          ...currentVersion,
          ...baseData,
          user: currentVersion?._embedded?.user,
          etag,
        }

        setData(newData)
      })
      .finally(() => {
        setDataLoading(false)
      })
  }, [baseData, assetId])

  // cache etag in data object, but do not render in UI
  const asset = { ...data }
  delete asset.etag

  return {
    asset: asset,
    loading: (!data && !data?.error) || isValidating,
    validating: isValidating,
    error: data?.error,
    mutate,
    getPrintAsset,
    updatePrintAsset,
    updatePrintAssetVersion,
    deletePrintAsset,
    addNewPrintAssetVersion,
    addPrintAssetNote,
    dataLoading,
  }
}

export default usePrintAsset
