import React, { useState, useEffect, useMemo, useCallback } from 'react'
import deepEqual from 'deep-equal'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { ReactComponent as DocumentIcon } from 'svgs/statuses/document.svg'
import { ReactComponent as BellIcon } from 'svgs/statuses/bell.svg'
import SearchIcon from '@mui/icons-material/Search'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import useDocuments, { useAllDocuments } from 'hooks/useDocuments'

import ResultsListHeader from './Header'
import DataList from 'components/common/DataList'
import BulkPreview from 'components/common/BulkPreview'
import OrderDetailPreviewContent from 'components/common/BulkPreview/components/PreviewContent/OrderDetail'
import ExportToCSV from 'components/common/ExportToCSV'

import trimString from 'helpers/node/trimString'

function ResultsList({
  orderId,
  filters,
  sidebarOpen,
  setSidebarOpen,
  recordType,
}) {
  // datalist
  const [sortList, setSortList] = useState([])

  const {
    page,
    pageSize,
    setTotal,
    setTotalPages,
    setActiveIndex,
    layoutType,
    setLayoutType,
    setPageSize,
    clearChecked,
    initialized,
    setInitialized,
    requestParams,
    setParams,
  } = useDataListContext()

  const filterList = {
    'filter[field]': filters.searchfields ?? undefined,
    'filter[term]': trimString(filters.search) ?? undefined,
  }

  const documentsAllHookParams = useMemo(() => {
    const filtersSearch = [
      `order_id|${trimString(orderId)}`,
      recordType !== 'all' && `record_type|${recordType}`,
      filters.searchfilter,
    ].filter((fs) => fs)

    return {
      search: filtersSearch.join('|'),
      embed: 'notification|notes|metadata|linked_notification_documents',
      ...filterList,
      ...sortList,
    }
  }, [filterList, sortList, recordType])

  const documentsHookParams = useMemo(() => {
    return {
      ...documentsAllHookParams,
      'paging[page]': page,
      'paging[page_size]': pageSize,
    }
  }, [documentsAllHookParams, page, pageSize])

  const {
    documents,
    total,
    loading,
    totalPages,
    pageSize: docPageSize,
    deactivateDocuments,
  } = useDocuments(documentsHookParams)

  const getStatusDisplay = useCallback((rowData) => {
    const { notification } = rowData._embedded ?? {}

    let success = true
    let display = 'Document'

    if (rowData?.record_type === 'notification') {
      const { status, type } = notification
      success = status === 'Delivered'
      display = `${type} Delivery ${success ? 'succeeded' : 'failed'}`
    }

    const types = {
      document: {
        icon: DocumentIcon,
        name: 'Document',
        display,
      },
      notification: {
        icon: BellIcon,
        name: 'Bell',
        display,
      },
    }

    const recordType = types[rowData.record_type]

    return {
      ...recordType,
      success,
      display,
      color: success ? 'success.main' : 'warning.main',
    }
  }, [])

  const prepareRowData = useCallback(
    (row) => ({
      type: getStatusDisplay(row),
      ...row,
    }),
    [getStatusDisplay]
  )

  const adjustedDocuments = useMemo(() => {
    const addMetadata = (document) => {
      const result = { ...document }
      const metadata = result._embedded.metadata

      if (document?._embedded?.metadata) {
        Object.keys(metadata).forEach((key) => {
          result[`_.metadata.${key}`] = metadata[key]
        })
      }

      return result
    }

    return documents.map(addMetadata)
  }, [documents])

  const getColumnDisplay = useCallback((col) => {
    const editDisplay = (display) =>
      display
        .replace(/^id$/gm, 'doc_id')
        .replace(/^alternate_id$/gm, 'doc_alternate_id')
        .replace(/_/gm, ' ')
        .replace(/.metadata./gm, '')
        .toUpperCase()

    return editDisplay(col)
  }, [])

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: adjustedDocuments ?? [],
    presetName: 'correspondence',
    getStatusDisplay,
    setSortList,
    prepareRowData,
    getColumnDisplay,
  })

  const { getCsvExport } = useAllDocuments(
    {
      ...documentsAllHookParams,
    },
    !loading ? total : 0,
    dataListProps.getDataDisplay,
    columns.map((col) => col.id),
    getColumnDisplay,
    10000
  )

  const [actionButtonProps] = useState([
    {
      children: (
        <>
          <SearchIcon />
          View
        </>
      ),
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'hover.main',
        },
      },
      onClick: ({ index }) => {
        setActiveIndex(index)
        setLayoutType('preview')
      },
    },
  ])

  useEffect(() => {
    setInitialized(!loading)
  }, [loading])

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
      setPageSize(docPageSize, false)
    }
  }, [total, totalPages])

  // resets checked whenever documents changes
  useEffect(() => {
    clearChecked()
  }, [page, total])

  // store search params in context (for swr)
  useEffect(() => {
    if (!deepEqual(documentsHookParams, requestParams))
      setParams(documentsHookParams)
  }, [documentsHookParams])

  const dataListComponent = useMemo(() => {
    const isPreview = layoutType === 'preview'
    const columnCount = isPreview ? 2 : 7

    return (
      <DataList
        {...dataListProps}
        dataList={dataList}
        columns={columns}
        columnCount={columnCount}
        loading={loading}
        validating={initialized}
        fixedButtonProps={actionButtonProps}
        layoutType={layoutType}
        visibleColumns={[
          'type',
          'id',
          'account_id',
          'alternate_id',
          '_.metadata.Description',
        ]}
        multiSelect={false}
        minWidth="auto"
        statusColName="type"
      />
    )
  }, [
    dataListProps,
    dataList,
    layoutType,
    actionButtonProps,
    columns,
    loading,
    initialized,
  ])

  const dataListDisplay = useMemo(() => {
    return (
      <BulkPreview
        dataListComponent={dataListComponent}
        dataList={dataList}
        dataListProps={dataListProps}
        columns={columns}
        loading={loading}
        PreviewComponent={OrderDetailPreviewContent}
        sidebarOpen={sidebarOpen}
        excludeFromBlur
        minWidth="auto"
      />
    )
  }, [
    dataList,
    dataListProps,
    loading,
    columns,
    dataListComponent,
    sidebarOpen,
  ])

  return (
    <>
      <Box
        sx={{
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        <Box sx={{ px: 0, pt: 6, pb: 4, width: '100%' }}>
          {adjustedDocuments.length > 0 ? (
            <ResultsListHeader
              deactivateDocuments={deactivateDocuments}
              open={sidebarOpen}
              setOpen={setSidebarOpen}
              loading={loading}
            />
          ) : (
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                color: 'darkgray.light',
              }}
            >
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <Box>No documents can be shown for this order.</Box>
              )}
            </Box>
          )}
          <Box sx={{ visibility: adjustedDocuments.length <= 0 && 'hidden' }}>
            {dataListDisplay}
          </Box>
          <ExportToCSV
            getCsvExport={getCsvExport}
            filename={`Order.Details.${Date.now()}.csv`}
            hide={loading || dataList.length <= 0}
          />
        </Box>
      </Box>
    </>
  )
}

export default ResultsList
