import useAuth from 'hooks/useAuth'
import { useUserData } from 'hooks/users'
import useConfiguration from 'hooks/useConfiguration'
import {
  DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS,
  PRIVILEGES,
} from 'utils/constants'
import { useCallback } from 'react'

/**
 * use this hook for instance-specific data rendering
 */
const useInstance = () => {
  const {
    user: { instanceId, isMPXAdmin },
  } = useAuth()
  const { hasPrivilege, currentModule } = useConfiguration()
  const { formatUserTimeZone } = useUserData()
  const metadataColumns = DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS[instanceId]

  const alterDocumentInboundItem = async (documentInboundItem) => {
    const fieldDefinitions = currentModule?.configurations?.find(
      (config) => config.configuration_type === 'field_definitions'
    )
    const fieldSets = fieldDefinitions?.fieldsets || []
    const alteredDocumentInboundItem = { ...documentInboundItem }

    if (fieldDefinitions) {
      try {
        fieldSets.forEach((fieldset) => {
          if (typeof fieldset.fields === 'object') {
            Object.entries(fieldset.fields).forEach(([_, value]) => {
              alteredDocumentInboundItem[value.field] =
                documentInboundItem._embedded.metadata[value.field]
            })
          }
        })
      } catch (err) {
        // continue
      }
    }

    if (Array.isArray(metadataColumns)) {
      metadataColumns
        .filter((col) => col.list && col.apiId)
        .forEach((col) => {
          alteredDocumentInboundItem[col.id] =
            documentInboundItem._embedded.metadata?.[col.apiId]
        })
    }

    return alteredDocumentInboundItem
  }

  const alterDocumentInboundItems = async (documentInboundItems) => {
    if (
      !Array.isArray(documentInboundItems) ||
      documentInboundItems.length === 0
    )
      return documentInboundItems

    if (DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS[`${instanceId}`]) {
      // append specific metadata cols to documentInboundItems
      return Promise.all(documentInboundItems.map(alterDocumentInboundItem))
    }

    return documentInboundItems
  }

  const getDigitalMailDisplayDocument = (document) => {
    if (!document) return {}

    const displayDocument = {
      status: document.status ?? 'N/A',
      category: document.category,
      assigned_to: document.assigned_to_display_name ?? 'N/A',
      type: 'GENERAL',
      account_number: document.account_id,
      tracking_number: document.tracking_number ?? 'N/A',
      inbound_document_id: document.id ?? 'N/A',
      date_created:
        document._embedded?.document?.datetime_created &&
        formatUserTimeZone(
          new Date(document._embedded?.document?.datetime_created)
        ),
      _embedded: document._embedded,
      file_size: '0B',
    }

    const fieldDefinitions = currentModule?.configurations?.find(
      (config) => config.configuration_type === 'field_definitions'
    )
    const fieldSets = fieldDefinitions?.fieldsets || []

    fieldSets.forEach((fieldset) => {
      if (typeof fieldset.fields === 'object') {
        Object.entries(fieldset.fields).forEach(([_, value]) => {
          displayDocument[value.field] = document[value.field]
        })
      }
    })

    if (Array.isArray(metadataColumns)) {
      metadataColumns.forEach((col) => {
        if (col.viewableByAdmin && !isMPXAdmin) {
          delete displayDocument[col.id]
          return
        }

        displayDocument[col.id] = document?._embedded?.metadata?.[col.apiId]

        if (instanceId === 'chubb') {
          if (
            col.apiId === 'DateCheckShipped' &&
            document?._embedded?.document
          ) {
            displayDocument[col.id] =
              document._embedded.document?.datetime_mailed
          }

          if (col.apiId === 'DepositDate' && document?._embedded?.document) {
            displayDocument[col.id] =
              document._embedded.document?.datetime_mailed
          }

          if (
            col.apiId === 'DateCheckPulled' &&
            document?._embedded?.pull_request &&
            // Only show the date if PulledOrShippedTracking is present
            document?._embedded?.metadata?.['PulledOrShippedTracking']
          ) {
            displayDocument[col.id] =
              document._embedded.pull_request?.datetime_created
          }
        }
      })
    }

    return displayDocument
  }

  const getDigitalMailFilters = () => {
    const filters = [
      'document_id',
      'account_id',
      'category',
      'status',
      'date_doc',
      'tracking_number',
    ]

    const canFilterUsers = hasPrivilege(PRIVILEGES.VIEW_ALL_ITEMS)

    if (canFilterUsers) {
      filters.push('assigned_to_user_id')
    }

    if (instanceId === 'evolentmpc') {
      filters.push('member_id')
    }

    return filters
  }

  const getDigitalMailVisibleColumns = useCallback(() => {
    const visibleColumns = [
      'document_id',
      'account_id',
      'category',
      'status',
      'date_doc',
      'assigned_to_display_name',
      'tracking_number',
    ]

    if (Array.isArray(metadataColumns)) {
      visibleColumns.push(
        ...metadataColumns.filter((col) => col.list).map((col) => col.id)
      )
    }

    return visibleColumns
  }, [metadataColumns])

  const getMailOriginalCustomAddresses = () => {
    // display address and update notes
    let customAddressIds = {
      return_to_sender: 'RETURN TO SENDER ',
    }

    // do not display address
    let nonAddressIds = {
      destroy: 'DESTROY ',
    }

    if (instanceId === 'chubb') {
      customAddressIds = {
        overnight: 'OVERNIGHT',
      }

      nonAddressIds = {
        void_check: 'VOID/DESTROY',
        chubb_deposit_to_citi: 'CHUBB DEPOSIT TO CITI ',
        healthy_paws_deposit_to_boa: 'Healthy Paws Deposit to BOA ',
        total_loss_evidence_locker: 'TOTAL LOSS - EVIDENCE LOCKER ',
      }
    }

    return { nonAddressIds, customAddressIds }
  }

  const showDigitalMailDetailsItem = ({ values, id, document }) => {
    if (instanceId === 'chubb') {
      const categorySpecificFields = {
        CheckIssueDate: ['checks - returned'],
        LOB: ['checks - inbound', 'checks - returned'],
        Payee: ['checks - returned'],
        ReturnReason: ['checks - returned'],
        Resolution: ['checks - returned'],
        DateCompleted: ['checks - returned'],
        CheckAmount: ['checks - inbound', 'checks - returned'],
        CheckNumber: ['checks - inbound', 'checks - returned'],
        CheckSender: ['checks - inbound', 'checks - returned'],
        CAName: ['checks - inbound', 'checks - returned'],
        DateCAReceived: ['checks - inbound', 'checks - returned'],
        System: ['checks - inbound', 'checks - returned'],
        DelayReason: ['checks - inbound', 'checks - returned'],
        DateCheckPulled: ['checks - inbound', 'checks - returned'],
        DateCheckShipped: ['checks - inbound', 'checks - returned'],
        DepositDate: ['checks - inbound', 'checks - returned'],
        PullsOrderID: ['batch receipts'],
        VinNumber: ['total loss'],
        ClaimantName: ['total loss'],
        CertificateOrderID: ['stamped certificate of mailing'],
        CertificateLotID: ['stamped certificate of mailing'],
      }

      if (categorySpecificFields[id]) {
        const show = categorySpecificFields[id].includes(
          values.category?.toLowerCase()
        )

        if (show && id === 'DepositDate') {
          return document?.lot_id === 3
        }

        return show
      }
    } else if (instanceId === 'Memic') {
      if (
        id === 'Drawer' ||
        id === 'FolderNumber' ||
        id === 'FileNumber' ||
        id === 'DocumentType' ||
        id === 'PageDescription'
      ) {
        switch (values.category?.toUpperCase()) {
          case 'PREMIUM ACCOUNTING':
          case 'PREMIUM AUDIT':
          case 'FINANCE':
          case 'UNDERWRITING':
          case 'LOSS CONTROL':
          case 'HUMAN RESOURCES':
          case '11409 RETURNED CHECKS':
          case '11409 RETURNED MAIL':
          case 'CASCO VIEW HOLDINGS CHECKS':
          case 'GENERAL MAIL':
          case 'CORRESPONDENCE - MIKE BOURQUE':
          case '11409 CLAIMS':
          case 'PAYMENT CHECKS':
            return false
          default:
            break
        }
      }
    }

    return true
  }

  const getCollateralAccountNumberLabel = ({ requireAccountNumber }) => {
    let label = 'Account Number'

    if (instanceId === 'metrocreditunion') {
      label = 'Account or Person #'
    }

    return `${label} ${requireAccountNumber ? '' : '(Optional)'}`
  }

  return {
    alterDocumentInboundItem,
    alterDocumentInboundItems,
    getDigitalMailDisplayDocument,
    getDigitalMailFilters,
    getDigitalMailVisibleColumns,
    getMailOriginalCustomAddresses,
    showDigitalMailDetailsItem,
    getCollateralAccountNumberLabel,
  }
}

export default useInstance
