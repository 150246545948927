import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import PopoverButton from 'components/common/PopoverButton'
import { usePrintAsset } from 'hooks/print_assets'
import { PRIVILEGES } from 'utils/constants'
import useConfiguration from 'hooks/useConfiguration'
import useAuth from 'hooks/useAuth'
import useNotification from 'hooks/context/useNotification'
import usePrintAssetConfiguration from 'hooks/print_assets/usePrintAssetConfiguration'

function AssetTableCol({ column, sortList, handleColumnClick }) {
  if (!column) return <TableCell />

  if (column.sortable) {
    return (
      <TableCell>
        <TableSortLabel
          active={
            sortList && sortList['sort[0]']?.match(new RegExp(`^${column.id}:`))
          }
          direction={sortList?.['sort[0]']?.split(':')?.[1]}
          onClick={() => handleColumnClick(column.id)}
        >
          {column.display}
        </TableSortLabel>
      </TableCell>
    )
  }

  return <TableCell>{column.display}</TableCell>
}

function AssetsTable({
  dataList,
  columns,
  getDataDisplay,
  sortList,
  setSortList,
  loading,
  setClientLoading,
  tabIndex,
  refetch,
}) {
  const { getPrintAssetVisibleColumns } = usePrintAssetConfiguration()
  const visibleColumns = useMemo(getPrintAssetVisibleColumns, [
    getPrintAssetVisibleColumns,
  ])
  const { updatePrintAsset, getPrintAsset } = usePrintAsset({})
  const { hasPrivilege } = useConfiguration()
  const { user } = useAuth()
  const { setBasicNotification } = useNotification()

  const handleColumnClick = useCallback(
    (col) => {
      // alternate between not sorted, asc, and desc
      if (!sortList['sort[0]']) {
        setSortList({ 'sort[0]': `${col}:asc` })
      } else if (sortList['sort[0]'] === `${col}:asc`) {
        setSortList({ 'sort[0]': `${col}:desc` })
      } else {
        setSortList({})
      }
    },
    [sortList, setSortList]
  )

  const getRowDisplay = useCallback(
    (row, col) => {
      if (col.match(/^TAG_/) && col.length > 4) {
        return row?.metadata?.[col.slice(4)] || 'N/A'
      }

      return getDataDisplay(row, col)
    },
    [getDataDisplay]
  )

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box
          sx={{
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="assets-table">
          <TableHead>
            <TableRow>
              {visibleColumns.map((col) => (
                <AssetTableCol
                  key={col}
                  column={columns.find((c) => c.id === col)}
                  handleColumnClick={handleColumnClick}
                  sortList={sortList}
                />
              ))}
              <TableCell>{/* Details */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {visibleColumns.map((col) => (
                  <TableCell key={col}>{getRowDisplay(row, col)}</TableCell>
                ))}
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      LinkComponent={Link}
                      to={`/correspondence-hub/print-asset/${row.key_id}`}
                    >
                      Details
                    </Button>
                    {user.isMPXAdmin &&
                      hasPrivilege(PRIVILEGES.ACCESS_INTERNAL_PRINT_ASSET) && (
                        <PopoverButton
                          popover
                          popoverMessage={
                            <Box sx={{ width: 250, textAlign: 'center' }}>
                              {tabIndex === 0
                                ? 'Moving this asset to MPX ASSETS will make it unavailable to non-admin users.'
                                : 'Moving this asset to PRINT ASSETS will make it available to all users.'}
                            </Box>
                          }
                          popoverProps={{
                            anchorOrigin: {
                              vertical: 40,
                              horizontal: -100,
                            },
                            BackdropProps: { invisible: false },
                          }}
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={async () => {
                            try {
                              setClientLoading(true)
                              const res = await getPrintAsset({
                                id: row.key_id,
                              })
                              const etag = res.headers.etag
                              await updatePrintAsset({
                                id: row.key_id,
                                updateData: {
                                  internal: tabIndex === 0 ? 1 : 0,
                                },
                                isForm: false,
                                etag,
                              })
                              await refetch()
                              setBasicNotification(
                                `${row.name} successfully moved to ${
                                  tabIndex === 0 ? 'MPX ASSETS' : 'PRINT ASSETS'
                                }.`
                              )
                            } finally {
                              setClientLoading(false)
                            }
                          }}
                        >
                          Move
                        </PopoverButton>
                      )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default AssetsTable
