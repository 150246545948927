import React from 'react'

import Button from '@mui/material/Button'

import LoadingModal from './LoadingModal'

import { COLORS } from 'utils/constants'

import useNotification from 'hooks/context/useNotification'
import saveData from 'helpers/browser/saveData'
import useModal from 'hooks/context/useModal'

function ExportToCSV({ filename, getCsvExport, onClick, sx, hide }) {
  const {
    setModalProps,
    setComponent: setModalComponent,
    setOpen: setModalOpen,
  } = useModal()
  const { setBasicNotification, setError } = useNotification()

  const handleClick = async () => {
    try {
      setModalProps({
        size: 'sm',
        hideButtonsBorder: true,
        title: 'A CSV export is in progress',
      })
      setModalComponent(LoadingModal)
      setModalOpen(true)
      const csv = await getCsvExport()
      setModalOpen(false)

      if (csv !== undefined) {
        saveData(csv, filename, 'text/csv')
        setBasicNotification(
          'A CSV file has been automatically downloaded to your computer.'
        )
      }
    } catch (err) {
      setError('An error has occurred.')
      setModalOpen(false)
    }
  }

  return (
    !hide && (
      <Button
        sx={{
          textDecoration: 'underline',
          mb: 1,
          fontWeight: 'bold',
          fontSize: 14,
          textAlign: 'left',
          border: 'none',
          bgcolor: 'transparent',
          cursor: 'pointer',
          color: COLORS.NAVY_BLUE,
          mt: 3,
          ...sx,
        }}
        onClick={onClick ? onClick : handleClick}
      >
        Export to CSV
      </Button>
    )
  )
}

export default ExportToCSV
