import { useCallback, useEffect, useState } from 'react'

import useAuth from 'hooks/useAuth'

const usePrintAssetConfiguration = () => {
  const {
    user: { instanceId },
  } = useAuth()
  const [configuration, setConfiguration] = useState({ metadata: null })

  const getPrintAssetDefaultSort = useCallback(() => {
    if (configuration.metadata) {
      const sortableField = Object.entries(configuration.metadata).find(
        ([k, v]) => v.sortable && v.sort
      )
      if (sortableField) {
        const dir =
          sortableField[1].sort?.toLowerCase() === 'asc' ? 'asc' : 'desc'
        return { 'sort[0]': `TAG_${sortableField[0]}:${dir}` }
      }
    }

    return {}
  }, [configuration])

  const getPrintAssetVisibleColumns = useCallback(() => {
    const visibleColumns = [
      'mime_type',
      'key_id',
      'name',
      'datetime_created',
      'datetime_modified',
      'collateral_available',
      'versions',
      'default_version',
    ]

    if (configuration.metadata) {
      Object.entries(configuration.metadata).forEach(([k, v]) => {
        if (!v.list) return

        if (Number.isInteger(v.position)) {
          visibleColumns.splice(v.position, 0, `TAG_${k}`)
        } else {
          visibleColumns.push(`TAG_${k}`)
        }
      })
    }

    return visibleColumns
  }, [configuration])

  const appendPrintAssetColumns = useCallback(
    (columns) => {
      if (configuration.metadata) {
        const extraCols = []

        Object.entries(configuration.metadata).forEach(([k, v]) => {
          if (!v.list) return
          extraCols.push({
            id: `TAG_${k}`,
            display: v.display || k,
            sortable: v.sortable ?? false,
          })
        })

        return columns.concat(extraCols)
      }

      return columns
    },
    [configuration]
  )

  const getPrintAssetDetailItems = useCallback(
    (asset) => {
      const detailItems = []

      if (configuration.metadata) {
        let tuple = []

        Object.entries(configuration.metadata).forEach(([k, v]) => {
          tuple.push({
            label: v.display || k,
            value: asset?.metadata?.[k],
          })

          if (tuple.length === 2) {
            detailItems.push(tuple)
            tuple = []
          }
        })

        if (tuple.length) {
          detailItems.push(tuple)
        }

        return detailItems
      }

      return []
    },
    [configuration]
  )

  useEffect(() => {
    if (instanceId === 'paracogas') {
      // adjust data structure as needed
      const data = {
        metadata: {
          Status: {
            // which column of print assets table to display this field
            position: 6,
            // whether this field appears in list view
            list: true,
            sortable: false,
            display: 'Status',
            data_type: 'select',
            options: {
              Enabled: 'Enabled',
              Disabled: 'Disabled',
            },
          },
          Priority: {
            display: 'Priority',
            data_type: 'number',
            list: true,
            sortable: true,
            sort: 'asc',
          },
        },
      }

      // configuration adjustments to simplify BE
      Object.entries(data.metadata || {}).forEach(([k, v]) => {
        v.field = `metadata__${k}`

        if (v.data_type === 'number') {
          v.inputProps = {
            type: 'number',
          }
        }
      })

      setConfiguration(data)
    }
  }, [instanceId])

  return {
    configuration,
    getPrintAssetDefaultSort,
    getPrintAssetVisibleColumns,
    appendPrintAssetColumns,
    getPrintAssetDetailItems,
  }
}

export default usePrintAssetConfiguration
