import React, { useRef, useMemo, useState } from 'react'

import Modal from 'components/common/Modal'
import FormRenderer from 'components/common/FormRenderer'

import useNotification from 'hooks/context/useNotification'
import get from 'lodash.get'
import set from 'lodash.set'

function EditFieldModal({
  open,
  setOpen,
  value,
  fieldData,
  asset,
  updatePrintAsset,
  updatePrintAssetVersion,
  addPrintAssetNote,
}) {
  const formikRef = useRef()
  const [loading, setLoading] = useState(false)
  const { setBasicNotification, setError } = useNotification()
  const { field: fieldKey, data_type: dataType } = fieldData || {}
  const field = fieldKey || ''
  const assetValue = get(asset, field)

  const defaultValue = useMemo(() => {
    if (value) {
      return value
    }

    if (dataType === 'tags') {
      // tags handle different.
      if (!assetValue || typeof assetValue !== 'object') {
        return []
      }

      if (Array.isArray(assetValue)) {
        // array should be populated, assume is array of [{name: ..., value: ...}, ...]
        return assetValue
      }

      // cast object of Key=>Value to array of [{name: ..., value: ...}, ...]
      return Object.entries(assetValue).map(([name, value]) => {
        return {
          name,
          value,
        }
      })
    }

    if (fieldKey === 'note') {
      return asset?._embedded?.notes?.[0]?.note || ''
    }

    return assetValue || ''
  }, [asset, value, assetValue, dataType, fieldKey])

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      let updateKey = field
      const updateData = {}

      if (field.includes('metadata__')) {
        updateKey = field.replace('metadata__', 'metadata.')
        // full metadata
        updateData.metadata = asset.metadata
      }

      set(updateData, updateKey, values[field])

      if (field === 'note') {
        await addPrintAssetNote({
          id: asset?.id,
          note: values[field],
        })
      }

      if (dataType === 'tags') {
        // tags = metadata.
        // if this occured, then the entire metadata field is messed up now, and must be converted
        const tagsObj = {}

        values[field].forEach((tag) => {
          if (tag.name) {
            tagsObj[tag.name] = tag.value
          }
        })

        updateData['metadata'] = tagsObj
      }

      await updatePrintAsset({
        id: asset?.id,
        updateData,
        isForm: false,
      })

      setBasicNotification('Asset has been successfully updated')
      setOpen(false)
    } catch (err) {
      setError(
        err?.response?.data?.display_message ||
          err?.message ||
          'Error updating asset'
      )
    } finally {
      setLoading(false)
    }
  }

  const validate = (values) => {
    const errors = {}

    if (values['note'] !== undefined && !values['note'].trim()) {
      errors[field] = 'Notes must have content'
    }

    if (values['name'] !== undefined && values['name'].trim().length > 40) {
      errors[field] = 'Name must be 40 characters or less'
    }

    return errors
  }

  return (
    <Modal
      hideButtonsBorder
      scrollable={dataType === 'tags'}
      title={`Edit ${fieldData?.display || ''}`}
      width="732px"
      height="auto"
      size={dataType === 'tags' ? 'lg' : undefined}
      open={open}
      footerButtonProps={[
        {
          children: 'Save',
          variant: 'contained',
          color: 'primary',
          size: 'action-header',
          onClick: () => formikRef.current.submitForm(),
          loading: loading,
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setOpen(false),
          disabled: loading,
        },
      ]}
    >
      <FormRenderer
        renderingData={[{ ...fieldData }]}
        initialData={{
          [field]: defaultValue,
        }}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
        validate={validate}
      />
    </Modal>
  )
}

export default EditFieldModal
